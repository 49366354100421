/**
 * INFO:
 * 意図せずにbootstrapが入っているため、それありきで実装されている
 */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ress';
import 'src/ui/themes/globals.css';
import 'src/infrastructure/firebase/analytics';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { RecoilRoot } from 'recoil';

import { Tenant } from 'src/domain/constants/tenant';
import { BaseToaster } from 'src/ui/components/elements/toasters/Toaster';
import { ApplicationInit } from 'src/ui/components/layouts/ApplicationInit';
import { LiffAuthInitializer } from 'src/ui/components/LiffAuthInitializer';
import { getPageTitle } from 'src/ui/constants/pageTitle';

import type { AppProps } from 'next/app';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const title = getPageTitle(router.pathname);
  return (
    <RecoilRoot>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
        />
        <title>{title}</title>
      </Head>
      {Tenant.needsLiffAuth(router.pathname) && !Tenant.isLiffNoAuthPath(router.pathname) && (
        <LiffAuthInitializer />
      )}
      <ApplicationInit>
        <Component {...pageProps} />
        <BaseToaster />
      </ApplicationInit>
    </RecoilRoot>
  );
};
export default MyApp;
