export const Path = {
  CHAT_INDEX: '/chat',
  CHAT_ROOM: '/chat/room',
  CHAT_TERMS: '/chat/terms',
  CHAT_NEW_USER: '/chat/user/new',
  OTHER_FEATURES: '/other-features',
  SETTINGS_PHONE_NUMBER: '/settings/phone-number',
  SETTINGS_ONE_TIME_PASSWORD: '/settings/one-time-password',
  AU_LOGIN: '/au/login',
  AU_TERMS: '/au/terms',
  AU_RE_TERMS: '/au/re-terms',
  AU_ERROR: '/au/error',
  AU_PHONE_NUMBER: '/au/phone-number',
  AU_ONE_TIME_PASSWORD: '/au/one-time-password',
  AU: '/au',
  AU_LOGIN_SUCCESS: '/au/login/success',
  AU_LOGIN_FAILURE: '/au/login/failure',
  AU_LOGOUT: '/au/logout',
} as const;
export type Path = (typeof Path)[keyof typeof Path];
