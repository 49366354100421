import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { getLiffIdToken, liff } from 'src/infrastructure/liff/client';
import { setSentryLineUser } from 'src/infrastructure/sentry/setSentryUser';
import { liffState } from 'src/ui/states/liffState';

/**
 * Liffの初期化とログイン処理を行い、認証情報のStateを更新する
 */
export const LiffAuthInitializer: React.FC = () => {
  const setCurrentLiff = useSetRecoilState(liffState);

  const initializeLiff = async () => {
    try {
      await liff?.init({
        liffId: process.env.NEXT_PUBLIC_LIFF_ID as string,
        withLoginOnExternalBrowser: process.env.NODE_ENV === 'development',
      });
      if (liff?.isLoggedIn()) {
        setCurrentLiff({ idToken: getLiffIdToken() });
        const profile = await liff.getProfile();
        setSentryLineUser({ lineId: profile.userId });
      } else {
        liff?.login({ redirectUri: window.location.href });
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    initializeLiff();
  }, []);
  return null;
};
