import { Toaster } from 'react-hot-toast';

import colors from 'src/ui/themes/colors';

export const BaseToaster: React.FC = () => (
  <Toaster
    position="top-center"
    toastOptions={{
      duration: 3000,
      style: {
        maxWidth: 324,
        width: '100%',
        height: '100%',
        paddingTop: '18px',
        paddingBottom: '18px',
        paddingRight: '7px',
        paddingLeft: '7px',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
      },
      icon: '',

      error: {
        style: {
          backgroundColor: `${colors.warning.negative.main}`,
          color: `${colors.text.ondark.main}`,
        },
      },

      success: {
        style: {
          backgroundColor: `${colors.warning.positive.main}`,
          color: `${colors.text.ondark.main}`,
        },
      },
    }}
  />
);
