import { Tenant } from 'src/domain/constants/tenant';
import { Path as ChatPath } from 'src/ui/constants/path';
import { Path as ReceptionPath } from 'src/ui/constants/reception';
import { Words } from 'src/ui/constants/words';

const ChatPageTitlePathMap: Record<ChatPath, string> = {
  [ChatPath.CHAT_INDEX]: Words.loading,
  [ChatPath.CHAT_ROOM]: Tenant.pharmacyName,
  [ChatPath.CHAT_TERMS]: Words.termsAndPrivacy,
  [ChatPath.CHAT_NEW_USER]: Words.termsAndPrivacy,
  [ChatPath.AU_LOGIN]: Words.login,
  [ChatPath.SETTINGS_PHONE_NUMBER]: Words.reVerifyPhoneNumber,
  [ChatPath.SETTINGS_ONE_TIME_PASSWORD]: Words.reVerifyPhoneNumber,
  [ChatPath.AU_TERMS]: Words.termsAndPrivacy,
  [ChatPath.AU_RE_TERMS]: Words.termsAndPrivacy,
  [ChatPath.AU_ERROR]: Tenant.pharmacyName,
  [ChatPath.AU_PHONE_NUMBER]: Words.signup,
  [ChatPath.AU_ONE_TIME_PASSWORD]: Words.signup,
  [ChatPath.AU]: Words.loading,
  [ChatPath.AU_LOGIN_SUCCESS]: Words.login,
  [ChatPath.AU_LOGIN_FAILURE]: Words.login,
  [ChatPath.AU_LOGOUT]: 'ログアウト',
  [ChatPath.OTHER_FEATURES]: 'その他の機能',
};

const ReceptionPageTitlePathMap: Record<ReceptionPath, string> = {
  [ReceptionPath.PATIENTS]: '受付',
  [ReceptionPath.FULL_NAME]: '氏名',
  [ReceptionPath.FULL_NAME_KANA]: '氏名(フリガナ)',
  [ReceptionPath.BIRTHDAY]: '誕生日',
  [ReceptionPath.GENDER]: '性別',
  [ReceptionPath.PHONE_NUMBER]: '電話番号',
  [ReceptionPath.INSURANCE_CARD]: '保険証',
  [ReceptionPath.STATEMENT_OF_RECEIPT]: '受給者証',
  [ReceptionPath.ALLERGY]: 'アレルギー',
  [ReceptionPath.SIDE_EFFECT]: '副作用',
  [ReceptionPath.SIDE_EFFECT_DESCRIPTION]: '副作用(詳細)',
  [ReceptionPath.CLINICAL_HISTORY]: '既往歴',
  [ReceptionPath.CLINICAL_HISTORY_DESCRIPTION]: '既往歴(詳細)',
  [ReceptionPath.FAVORITE_ITEM]: '嗜好品',
  [ReceptionPath.WEIGHT]: '体重',
  [ReceptionPath.DANGEROUS_TASK]: '危険作業',
  [ReceptionPath.PREGNANCY]: '妊娠',
  [ReceptionPath.BREAST_FEEDING]: '授乳',
  [ReceptionPath.HEALTHY_FOOD]: '健康食品・市販薬',
  [ReceptionPath.HEALTHY_FOOD_DESCRIPTION]: '健康食品・市販薬(詳細)',
  [ReceptionPath.MEDICAL_NOTEBOOK]: 'お薬手帳',
  [ReceptionPath.MEDICATION_GUIDANCE_SCHEDULE]: 'お薬説明の予約',
  [ReceptionPath.POSTAL_CODE]: '郵便番号',
  [ReceptionPath.ADDRESS]: '住所',
  [ReceptionPath.SHIPMENT_METHOD]: '配送方法',
  [ReceptionPath.PAYMENT_METHOD]: '決済方法',
  [ReceptionPath.REQUEST]: 'その他質問・相談',
  [ReceptionPath.CONFIRMATION]: '最終確認',
  [ReceptionPath.COMPLETE]: '受付完了',
  [ReceptionPath.MEDICATION_GUIDANCE_EDIT]: 'お薬説明の予約変更',
  [ReceptionPath.MEDICATION_GUIDANCE_THANKS]: '受付完了',
} as const;

export const getPageTitle = (path: string): string => {
  return (
    ChatPageTitlePathMap[path as ChatPath] ??
    ReceptionPageTitlePathMap[path as ReceptionPath] ??
    Tenant.pharmacyName
  );
};
