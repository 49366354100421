import styled, { keyframes } from 'styled-components';

import colors from 'src/ui/themes/colors';
import { RemSize } from 'src/ui/types/size';

type Props = {
  size?: RemSize;
  borderSize?: RemSize;
  color?: string;
};

const LoaderKeyframes = keyframes`0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const LoaderBase = styled.div<{
  size: RemSize;
  borderSize: RemSize;
  color: string;
}>`
  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-top: ${({ borderSize, color }) => `${borderSize} solid ${color}33`};
  border-right: ${({ borderSize, color }) => `${borderSize} solid ${color}33`};
  border-bottom: ${({ borderSize, color }) => `${borderSize} solid ${color}33`};
  border-left: ${({ borderSize, color }) => `${borderSize} solid ${color}`};
  transform: translateZ(0);
  animation: ${LoaderKeyframes} 1.1s infinite linear;
`;

export const LoaderLarge: React.FC<Props> = ({
  size = '5rem',
  borderSize = '0.5rem',
  color = colors.main.primary.main,
}) => <LoaderBase data-testid="loader" size={size} borderSize={borderSize} color={color} />;

export const LoaderSmall: React.FC<Props> = ({
  size = '2rem',
  borderSize = '0.3rem',
  color = colors.main.primary.main,
}) => <LoaderBase data-testid="loader" size={size} borderSize={borderSize} color={color} />;

export const LoaderWhiteLarge: React.FC<Props> = ({
  size = '5rem',
  borderSize = '0.5rem',
  color = colors.background.primary.main,
}) => <LoaderBase data-testid="loader" size={size} borderSize={borderSize} color={color} />;
