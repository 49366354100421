import styled from 'styled-components';

import { LoaderLarge } from 'src/ui/components/elements/Loader';

export const LoadingView: React.FC = () => {
  return (
    <Main>
      <LoaderLarge />
    </Main>
  );
};

const Main = styled.div`
  margin: 60px auto;
  display: flex;
  justify-content: center;
`;
