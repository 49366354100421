import { Tenant } from 'src/domain/constants/tenant';

export const Words = {
  commonNetworkErrorTitle: 'サーバーへ接続ができません',
  commonNetworkErrorMessage: 'インターネット接続を確認してください',
  commonDataFetchErrorTitle: 'データの取得に失敗しました',
  commonDataFetchErrorMessage: '一度チャット画面を閉じて、もう一度開き直してください',
  commonVerificationTooManyRequestErrorTitle: '認証コードは送信済みです',
  commonVerificationTooManyRequestErrorMessage:
    '届かない場合は、1分程度時間をおいて再度お試しください',
  commonErrorTitle: 'エラーが発生しました',
  commonErrorMessage: 'もう一度やり直してください',
  commonInvalidCodeErrorTitle: '認証コードが正しくありません',
  commonInvalidCodeErrorMessage: '認証コードを再度ご確認ください',
  commonVerificationTimeoutErrorTitle: '認証コードの有効期限が切れています',
  commonVerificationTimeoutErrorMessage: 'もう一度認証コードを再送し直してください',
  commonResendCodeModalContent:
    '上記の番号にSMSで認証コードを再送信します。電話番号を変更する場合は電話番号の入力ページに戻ってください',
  legalDocumentAlertTitle: '内容をご確認ください',
  legalDocumentAlertMessage:
    '「au薬局 利用規約」及び「au薬局における個人情報の取り扱い」をお読みいただき、ご同意いただきますようお願いいたします。',
  termsAndPrivacy: '利用規約・個人情報の取り扱い',
  reVerifyPhoneNumber: '電話番号の再認証',
  login: 'ログイン',
  signup: `${Tenant.pharmacyName} 新規登録`,
  loading: '読み込み中',
  inputPhoneNumberPrefix: '上3桁は070 080 090のいずれかで入力してください',
  inputOverElevenLength: '11桁で入力してください',
  inputOnlyNumber: '数字のみで入力してください',
} as const;
