import * as Sentry from '@sentry/nextjs';

const SENTRY_TAG_KEY = {
  talkChannelId: 'talk_channel_id',
} as const;
export type SENTRY_TAG_KEY = keyof typeof SENTRY_TAG_KEY;

export const setSentryTag = (key: SENTRY_TAG_KEY, value: string | null | undefined) => {
  Sentry.setTag(SENTRY_TAG_KEY[key], value);
};
