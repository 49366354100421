import { SessionInvalidError } from 'src/domain/errors/sessionInvalidError';

import type { Liff } from '@line/liff';

/**
 * Liffのライブラリは、ブラウザ依存しており、Nodeで動かないため、NextJsようにSSRではimportしないようにする
 * @see https://github.com/line/create-liff-app/blob/16e2c3ed7e16b3c2b1af6007c6a21f3c050f31e9/templates/nextjs-ts/pages/_app.tsx#L13-L15
 */
export const liff: Liff | undefined =
  typeof window !== 'undefined' && process.env.NODE_ENV !== 'test'
    ? require('@line/liff')
    : undefined;

export const getLiffIdToken: () => string = () => {
  const token = liff?.getIDToken();
  if (!token) {
    throw new SessionInvalidError('Liff idToken is empty.');
  }
  return token;
};
