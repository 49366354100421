import { atom } from 'recoil';

interface Liff {
  idToken: string;
}

// not undefined  : ログイン処理完了済
// undefined : ログイン処理未完了
export const liffState = atom<Liff | undefined>({
  key: 'liffState',
  default: undefined,
});
