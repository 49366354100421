import * as Sentry from '@sentry/nextjs';

export type SentryLineUser = {
  lineId: string;
};

export const setSentryLineUser = (user: SentryLineUser) => {
  Sentry.setUser(user);
};

export type SentryFirebaseUser = {
  firebaseUid: string;
};

export const setSentryFirebaseUser = (user: SentryFirebaseUser) => {
  Sentry.setUser(user);
};
