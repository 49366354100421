import { getApp, getApps, initializeApp } from 'firebase/app';

const emulatorProjectId = process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_PROJECT_ID;

const firebaseConfig = emulatorProjectId
  ? {
      apiKey: 'test',
      authDomain: 'test',
      projectId: emulatorProjectId,
      storageBucket: 'test',
      messagingSenderId: 'test',
      appId: 'test',
    }
  : {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
    };
export const app = getApps().length <= 0 ? initializeApp(firebaseConfig) : getApp();
export const useEmulator = !!emulatorProjectId;
