// TODO medicalNoteBookPageでしか使っていないので、features配下に定数のファイルを置いておきたい
import { HasMedicalNoteBook } from 'src/ui/types/medicalNotebook';

export const HAS_MEDICAL_NOTEBOOK: {
  hasMedicalNotebook: HasMedicalNoteBook;
  notHasMedicalNotebook: HasMedicalNoteBook;
  wantMakeMedicalNotebook: HasMedicalNoteBook;
  skipWithGuidance: HasMedicalNoteBook;
  skipWithoutGuidance: HasMedicalNoteBook;
} = {
  hasMedicalNotebook: 'have',
  notHasMedicalNotebook: 'notHave',
  wantMakeMedicalNotebook: 'wantMake',
  skipWithGuidance: 'skipWithGuidance',
  skipWithoutGuidance: 'skipWithoutGuidance',
};
