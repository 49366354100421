import { HAS_MEDICAL_NOTEBOOK } from 'src/ui/constants/medicalNotebook';
import { Interview } from 'src/ui/types/reception/interview';
import { Reception } from 'src/ui/types/reception/reception';

/* 受付の画面遷移関係 */
export const Path = {
  PATIENTS: '/reception/patients',
  FULL_NAME: '/reception/fullName',
  FULL_NAME_KANA: '/reception/fullNameKana',
  BIRTHDAY: '/reception/birthday',
  GENDER: '/reception/gender',
  PHONE_NUMBER: '/reception/phoneNumber',
  INSURANCE_CARD: '/reception/insuranceCard',
  STATEMENT_OF_RECEIPT: '/reception/statementOfReceipt',
  ALLERGY: '/reception/allergy',
  SIDE_EFFECT: '/reception/sideEffect',
  SIDE_EFFECT_DESCRIPTION: '/reception/sideEffectDescription',
  CLINICAL_HISTORY: '/reception/clinicalHistory',
  CLINICAL_HISTORY_DESCRIPTION: '/reception/clinicalHistoryDescription',
  FAVORITE_ITEM: '/reception/favoriteItem',
  WEIGHT: '/reception/weight',
  DANGEROUS_TASK: '/reception/dangerousTask',
  PREGNANCY: '/reception/pregnancy',
  BREAST_FEEDING: '/reception/breastFeeding',
  HEALTHY_FOOD: '/reception/healthyFood',
  HEALTHY_FOOD_DESCRIPTION: '/reception/healthyFoodDescription',
  MEDICAL_NOTEBOOK: '/reception/medicalNotebook',
  MEDICATION_GUIDANCE_SCHEDULE: '/reception/medicationGuidanceSchedule',
  POSTAL_CODE: '/reception/postalCode',
  ADDRESS: '/reception/address',
  SHIPMENT_METHOD: '/reception/shipmentMethod',
  PAYMENT_METHOD: '/reception/paymentMethod',
  REQUEST: '/reception/request',
  CONFIRMATION: '/reception/confirmation',
  COMPLETE: '/reception/complete',
  MEDICATION_GUIDANCE_EDIT: '/reception/medicationGuidanceSchedule/edit',
  MEDICATION_GUIDANCE_THANKS: '/reception/medicationGuidanceSchedule/thanks',
} as const;

export type Path = (typeof Path)[keyof typeof Path];

/* 受付のstateの初期値 */
export const STATE_DEFAULT_PATIENT_ID = '-1';

export const INTERVIEW_STATE_DEFAULT_VALUE: Interview = {
  isFirstInterview: true,
  latestAnswerDate: '',
  insuranceCardPage: {
    fileUrl: '',
    insuranceCardId: -1,
  },
  statementOfReceiptPage: {
    hasStatementOfReceipt: undefined,
    files: [],
  },
  fullNamePage: {
    lastName: '',
    firstName: '',
  },
  fullNameKanaPage: {
    lastNameKana: '',
    firstNameKana: '',
  },
  birthdayPage: {
    year: '',
    month: '',
    day: '',
  },
  genderPage: {
    gender: 'notSelect', // 'notSelect' | 'male' | 'female'
  },
  phoneNumberPage: {
    phone1: '',
    phone2: '',
    phone3: '',
  },
  allergyPage: [],
  sideEffectPage: {
    isSideEffect: undefined,
    description: '',
  },
  clinicalHistoryPage: {
    isClinicalHistory: undefined,
    selectedClinicalHistories: [],
  },
  favoriteItemPage: [],
  weightPage: {
    weight: -1,
  },
  dangerousTaskPage: {
    isDangerousTask: undefined,
  },
  pregnancyPage: {
    isPregnancy: undefined,
  },
  breastfeedingPage: {
    isBreastfeeding: undefined,
  },
  healthyFoodPage: {
    isHealthyFood: undefined,
    description: '',
  },
  // お薬手帳は、持参率向上のため、初期値 'have' にする
  medicalNotebookPage: {
    medicalNotebookStatus: HAS_MEDICAL_NOTEBOOK.hasMedicalNotebook,
    files: [],
  },
  medicationGuidanceSchedulePage: {
    selectedDate: '',
    selectedTime: '',
    medicationGuidanceScheduleId: -1,
  },
  postalCodePage: {
    postalCode1: '',
    postalCode2: '',
  },
  addressPage: {
    address1: '', // 都道府県
    address2: '', // 市区町村
    address3: '', // 番地
    address4: '', // アパート・部屋番号
  },
  shipmentMethodPage: {
    shipmentMethodName: '',
    shipmentMethodId: -1, // マスターのグローバルstateと照会する
  },
  paymentMethodPage: {
    paymentMethodName: '',
    paymentMethodId: -1, // マスターのグローバルstateと照会する
  },
  requestPage: {
    description: '',
  },
};

export const RECEPTION_STATE_DEFAULT_VALUE: Reception = {
  currentPatientId: -1,
  interviews: {
    '-1': INTERVIEW_STATE_DEFAULT_VALUE,
  },
};
