import { getAnalytics, logEvent as logEventOrigin, setUserProperties } from 'firebase/analytics';

import { app } from 'src/infrastructure/firebase/app';

export type EventName =
  // au新規登録-電話番号登録にて、既に同じ電話番号を登録済みのユーザーにダイアログを表示する
  | 'display_duplicate_phone_number_dialog'
  // au新規登録-電話番号登録、電話番号重複ダイアログにて「登録する」を選択する
  | 'action_continue_on_duplicate_phone_number'
  // au新規登録-電話番号登録、電話番号重複ダイアログにて「別のau IDでログインし直す」を選択する
  | 'action_relogin_on_duplicate_phone_number'
  // Webチャット遷移時、電話番号が無効化されているユーザーに対して、ダイアログを表示する
  | 'display_invalid_phone_number_dialog'
  // Webチャット、電話番号が無効化ダイアログにて「電話番号変更」を選択する
  | 'action_update_phone_number_on_invalid_phone_number'
  // Webチャット、電話番号が無効化ダイアログにて「別のau IDでログインし直す」を選択する
  | 'action_relogin_on_invalid_phone_number';

type AuthenticationType = 'au ID' | 'LINE';

export const logEvent = (eventName: EventName) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  logEventOrigin(getAnalytics(app), eventName);
};

export const setAuthenticationType = (type: AuthenticationType) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  setUserProperties(getAnalytics(app), {
    authentication_type: type,
  });
};

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
  getAnalytics(app);
}
