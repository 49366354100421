const colors = {
  main: {
    primary: {
      light: '#DBEEE3',
      main: '#7DB894',
      dark: '#569870',
    },
    secondary: {
      light: '#838383',
      main: '#6A6A6A',
      dark: '#464646',
    },
  },
  warning: {
    positive: {
      light: '#DBEEE3',
      main: '#7DB894',
      dark: '#569870',
    },
    negative: {
      light: '#FFE1E1',
      main: '#F39191',
      dark: '#D36A6A',
    },
  },
  sub: {
    a: {
      light: '#DFDC92',
      main: '#B9B552',
      dark: '#676418',
    },
    b: {
      light: '#D4DEFF',
      main: '#839EFF',
      dark: '#3A51A3',
    },
  },
  text: {
    positive: {
      light: '#878787',
      main: '#4D4B4B',
      dark: '#2B2B2B',
    },
    negative: {
      light: '#FFE1E1',
      main: '#F39191',
      dark: '#D36A6A',
    },
    link: {
      light: '#DBEEE3',
      main: '#7DB894',
      dark: '#569870',
    },
    ondark: {
      light: '#E3E3E3',
      main: '#FFFFFF',
      dark: '#A5A5A5',
    },
  },
  background: {
    primary: {
      main: '#FFFFFF',
      dark1: '#F5F5F5',
      dark2: '#D9D9D9',
      dark3: '#707070',
    },
    secondary: {
      main: '#DBEEE3',
      dark1: '#ACD3BC',
      dark2: '#7DB894',
      dark3: '#569870',
    },
  },
  kddi: {
    gray: {
      main: '#A8ABC4',
      secondary: '#707991',
    },
    lightGray: {
      main: '#F2F5FC',
      secondary: '#777777',
    },
    lightBlue: {
      main: '#E3EDFB',
    },
    lightorange: {
      main: '#FFD4A9',
    },
    blue: {
      main: '#0F77F0',
      secondary: '#4950AF',
    },
    error: {
      main: '#C70606',
    },
    navyBlue: {
      main: '#0F0453',
    },
    pink: {
      main: '#EE8085',
    },
  },
  au: {
    main: '#EB5505',
  },
  bubble: {
    primary: {
      main: '#D5D8DD',
    },
  },
};

export default colors;
