import { liff } from 'src/infrastructure/liff/client';
import { Path as ChatPath } from 'src/ui/constants/path';

export type Platform = 'line' | 'web';
export type TenantType = 'pharmaX' | 'kddi';
export type PharmacyName =
  | 'au薬局'
  | 'YOJO薬局四谷店'
  | 'オンライン薬局 with auウェルネス'
  | 'オンライン薬局';

export class Tenant {
  static get talkChannelId(): string {
    const id = process.env.NEXT_PUBLIC_TALK_CHANNEL_ID;
    if (!id) {
      throw Error('NEXT_PUBLIC_TALK_CHANNEL_ID is undefined.');
    }
    return id;
  }

  static get platform(): Platform {
    // TODO: 本来は、サーバーから取得したAPIからフィルターするのが望ましい
    // 現状は、初期化のタイミングなど整理がついていないため、暫定で固定の値(4はKDDI:WebChat)と比較する
    return Tenant.talkChannelId === '4' ? 'web' : 'line';
  }

  static get tenantType(): TenantType {
    // TODO: 本来は、サーバーから取得したAPIからフィルターするのが望ましい
    // 現状は、初期化のタイミングなど整理がついていないため、暫定で固定の値(3はKDDI:line, 4はKDDI:WebChat)と比較する
    return Tenant.talkChannelId === '3' || Tenant.talkChannelId === '4' ? 'kddi' : 'pharmaX';
  }

  static get pharmacyName(): PharmacyName {
    switch (this.talkChannelId) {
      case '1':
        return 'YOJO薬局四谷店';
      case '2':
        return 'オンライン薬局 with auウェルネス';
      case '3':
      case '4':
        return 'au薬局';
      default:
        return 'オンライン薬局';
    }
  }

  /**
   * LIFF認証が必要かどうか
   * Liffブラウザ内であればtrueを返す
   */
  static needsLiffAuth(pagePath: string): boolean {
    if (this.isLiffNoAuthPath(pagePath)) {
      return false;
    }
    return liff?.isInClient() || process.env.NEXT_PUBLIC_DEV_FORCE_LIFF_AUTH === '1' || false;
  }

  /**
   * Liff認証が不要なパスかどうかを判定する(不要の場合は、trueを返す)
   * NOTE: Liffの読み込みエラーがチャット画面で頻発しているため、その実験的対応として,
   * Liff認証が現時点で不要な箇所(getLiffIdTokenを使っていない)については、Liffの初期化自体をスキップする。
   * Tenant.tsが肥大化してきたため、効果がありそうなら、Liff,Firebase判定を別のファイルに移動することを検討する。
   */
  static isLiffNoAuthPath(path: string): boolean {
    return path === ChatPath.CHAT_ROOM || path.startsWith('/reception');
  }

  /**
   * Firebase認証が必要かどうか
   * talkChannelId < 3 LiffのIdToken管理のため、Firebase認証は不要
   * talkChannelId <= 3 FirebaseのIdToken管理のため、Firebase認証が必要
   */
  static get needsFirebaseAuth(): boolean {
    switch (this.talkChannelId) {
      case '1':
      case '2':
        return false;
      case '3':
      case '4':
        return true;
      default:
        return false;
    }
  }
}
